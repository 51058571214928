import React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as S from "../components/Post/styled"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <S.MainContent>
      <h1>Nenhum conteúdo encontrado</h1>
      <p>A url pesquisada não encontrou nenhum conteúdo deste site.</p>
      <p>Utilize a busca (ícone de lupa na barra lateral direita) para pesquisar algum conteúdo do seu interesse.</p>
    </S.MainContent>
  </Layout>
)

export default NotFoundPage
